@import '../../styles/vars.scss';

.registration-initial-page {
    justify-content: flex-start;
}

.registration-page-wrapper {
    width: 93.5%;
    height: 65vh;
    margin: 20px 50px;
    @include sectionShadow;
    background-color: $white;
    border-radius: 8px;
    box-sizing: border-box;

    &.full-height {
        height: 78vh;
    }

    .registration-tabs-wrapper {
        @include flex;
        border-radius: 0 0 20px 20px;
        background-color: $light-blue;

        .tab {
            width: 100%;
            padding: 10px;
            height: 40px;
            font-size: 14px;
            font-family: $fonts-bold;
            text-transform: uppercase;
            cursor: pointer;
            text-align: center;
            background-color: #EBF5FF;
            transition: 0.5s;

            &.selected {
                background-color: $medium-blue;
                color: $white;
                transition: 0.5s;
            }
            &.disabled {
                cursor: default;
            }
            &.start {
                border-radius: 0 0 20px 20px;
            }
            &.center {
                border-radius: 20px;
            }
            &.end {
                border-radius: 0 0 20px 20px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .registration-page-wrapper {
        
        &.full-height {
            box-shadow: none;
            margin: 20px 60px;
        }
        &.general, 
        &.review {
            height: fit-content;
        }

        .registration-tabs-wrapper {
            display: none;
        }
    }
}