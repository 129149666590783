@import "../../../styles/vars.scss";

.registration {
  padding: 0 20px;

  .section {
    padding-bottom: 20px;
  }

  .flex.mobile {
    display: none;
  }

  .section-top-wrapper {
    @include flex;
    margin-top: 20px;

    h1 {
      @include largerHeading;
      font-size: 20px;
    }

    p {
      @include smallestHeading;
    }

    button {
      @include secondaryBttn;
      margin-left: 0;
      margin-right: 0;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    .tab.center {
      display: none;
    }
  }

  .section-main-wrapper {
    @include flex;
    align-items: center;
    justify-content: flex-start;
    width: 88%;
  }

  .button.mobile {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .registration {
    &.first-tab {
      padding: 0;

      .section {
        .section-top-wrapper {
          flex-direction: column;

          .tab.center {
            display: block;
          }
        }

        .section-main-wrapper {
          flex-wrap: wrap;
          flex-direction: column;
          margin-right: 0;
          width: 100%;

          .input-wrapper {
            &.small,
            &.large {
              margin-right: 0;
              min-width: 100%;

              input,
              .input-arrow-wrapper,
              .counter-box-wrapper {
                height: 50px;
                max-width: 100%;

                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .button.mobile {
        display: block;
        @include primaryBttn;
        border: 1px solid $medium-blue;
        background-color: transparent;
        color: $black-text;
        width: 100%;
        margin: 20px auto;

        &:disabled {
            opacity: 0.5;
        }
      }
    }

    &.second-tab {
      .section {
        .section-main-wrapper {
          .continue-button-mobile {
            display: block;
            @include secondaryBttn;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            font-size: 16px;

            &:disabled {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
      }
    }

    .section {
      .section-top-wrapper {
        flex-direction: column;
        align-items: flex-start;

        .flex.mobile {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-transform: uppercase;
    
            h4 {
                font-size: 16px;
                font-family: $fonts-bold;
                margin-left: 15px;
            }
        }

        .tab {
            width: 100%;
            padding: 16px;
            // height: 40px;
            font-size: 16px;
            font-family: $fonts-bold;
            text-transform: uppercase;
            cursor: pointer;
            text-align: center;
            background-color: #ebf5ff;
            transition: 0.5s;
            border-radius: 0 0 20px 20px;
            margin-bottom: 20px;
          }

        button {
          display: none;
        }

        .text-wrapper {
          p {
            font-size: 20px;
          }
        }
      }

      .section-main-wrapper {
        .input-wrapper {
          p {
            font-size: 16px;
          }

          input {
            font-size: 18px;
          }
        }

        button {
          font-size: 16px;
        }
      }
    }
  }
}
