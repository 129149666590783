@import '../../styles/vars.scss';

.past-events-wrapper {
    @include flex;
    flex-direction: column;
    // margin: 0 40px;
    padding-bottom: 100px;
    background-image: url('../../assets/images/mountains-two.png');
    background-repeat: no-repeat;
    background-position: left bottom;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    .photo-wrapper {
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    h1 {
        @include mediumHeading;
    }

    .img-sections {
        @include flex;
        justify-content: center;
        flex-wrap: nowrap;
        min-width: calc(100% - 200px);
        max-width: calc(100% - 100px);

        .section {
            // width: 43vw;

            .image-wrapper {
                position: relative;
                width: fit-content;
                height: fit-content;
                transition: 1s;
                margin: 10px;
                
                .hovered {
                    position: absolute;
                    top: 0;
                    min-width: 100%;
                    min-height: 100%;
                    z-index: 0;
                    border-radius: 24px;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: 1s;
                    display: none;

                    .hover-text {
                        color: $white;
                        transition: 1s;

                        &.light {
                            @include smallHeading;
                            color: $white;
                        }
                        &.bold {
                            font-size: 22px;
                            font-family: $fonts-bold;
                            padding-bottom: 10px;
                        }
                    }
                    .hover-logo {
                        transition: 1s;
                        width: 134px;
                        padding-bottom: 20px;
                    }
                }

                .photo {
                    margin: 0;
                    z-index: 8;
                    transition: 1s;
                }

                .photo-wrapper {
                    transition: 1s;

                    &:hover {
                        filter: brightness(40%);
                        transition: 1s;
                    }

                    &:hover + .hovered,  
                    &:focus + .hovered  {
                        pointer-events:none;
                        display: flex;
                        transition: 2s;
                    }
                }

                .photo:hover + .hovered {
                    display: flex;
                    transition: 1s;
                }
            }
            
            &.left {
                flex-wrap: nowrap;
                justify-content: center;

                .row {
                    @include flex;
                    margin: 0;
                }
            }
            &.right {
                @include flex;
                .column {
                    @include flex;
                    margin: 0;
                    min-height: 100%;
                    flex-direction: column;
                    
                    .image-wrapper.column {
                        margin: 16px 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .past-events-wrapper {
        margin: 0 20px 20px 20px;
        padding-bottom: none;
        background-image: none;

        h1 {
            @include mediumHeadingMobile;
        }

        .img-sections {
            flex-wrap: wrap;
            min-width: 100%;

            .section {
                flex-direction: column;

                &.left {
                    min-width: 100%;

                    div {
                        flex-direction: column;
                    }

                    .row {
                        width: 100%;
                    }
                }
                &.right {
                    width: 100%;

                    .column {
                        width: 100%;
                    }
                }

                .image-wrapper {
                    @include flex;
                    flex-direction: column;
                    margin: 10px 0 40px 0;
                    // margin-bottom: 40px;
                    width: 100%;

                    .photo-wrapper {
                        order: 2;

                        img {
                            width: 100%;
                        }
                        
                        &:hover {
                            pointer-events: none;
                            filter: brightness(100%);
                        }
                    }

                    .hovered {
                        position: relative;
                        @include flex;
                        order: 1;
                        align-self: center;
                        margin-bottom: 10px;

                        .hover-logo {
                            padding-bottom: 10px;
                        }

                        .hover-text {
                            color: $black-text;

                            &.bold {
                                @include smallHeadingMobile;
                                font-size: 18px;
                            }
                            &.light {
                                @include smallHeading;
                                font-size: 16px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}