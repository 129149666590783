@import "../../styles/vars.scss";

.registration-initial-page-wrapper.countdown {
  h1 {
    margin-bottom: 80px;
  }
}

.registration-counter-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .card-wrapper {
    background-color: $dark-blue;
    border-radius: 20px;
    color: $white;
    width: 60vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: calc(15vh - 90px) calc(10vw - 80px);

    h1 {
      @include mediumHeading;
      color: $white;
    }

    .closed-registration-img {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      justify-content: center;

      img {
        position: absolute;
        top: 0;
        margin: 0 auto;
        width: calc(50vh - 70px);
        height: auto;
      }
    }

    .counter-wrapper {
      display: flex;
      //   flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      margin-top: 15px;

      .flex {
        display: flex;
        justify-content: space-between;

        &.column {
          flex-direction: column;
          width: 80%;
        }
      }

      .counter-text-wrapper {
        // flex: 1;
        width: 150px;
        text-align: center;
      }

      .time-count,
      .semi-colon {
        @include largerHeading;
        color: $white;
        font-size: 80px;
      }
    }
  }

  img {
    position: absolute;
    bottom: -20px;
    width: 100vw;
    height: auto;
  }
}

@media screen and (max-width: 1100px) {
  .registration-counter-wrapper {
    .card-wrapper {
      padding: calc(25vh - 90px) 10px;
      align-items: center;
      text-align: center;

      h1 {
        font-size: 20px;
      }

      .counter-wrapper {
        .counter-text-wrapper {
          .time-count {
            font-size: 55px;
          }
        }

        .semi-colon {
          font-size: 55px;
        }
      }
    }

    img {
      // bottom: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .registration-counter-wrapper {
    .card-wrapper {
      width: 86vw;

      .counter-wrapper {
        .counter-text-wrapper {
          .time-count-text {
            font-size: 12px;
          }

          .time-count {
            font-size: 30px;
          }
        }

        .semi-colon {
          font-size: 30px;
        }
      }
    }
  }
}
