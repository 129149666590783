@import "../../../styles/vars.scss";

.modal-wrapper {
  &.delete {
    .content-wrapper {
      .bttn-wrapper {
        @include flex;
        width: 100%;
        margin-top: 15px;

        .modal-bttn {
          flex: 1;
          height: 40px;
          @include primaryBttn;
          @include flex;
          justify-content: center;
          font-size: 14px;
          margin: 0;

          &.blue {
            background-color: $dark-blue;
            margin-right: 10px;
          }
          &.red {
            background-color: $red;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .modal-wrapper {
    &.delete {
      .content-wrapper {
        .bttn-wrapper {
          flex-direction: column;
          width: 100%;

          .modal-bttn {
            width: 100%;

            &.blue {
              margin-right: 0;
              margin-top: 10px;
              order: 2;
            }
          }
        }
      }
    }
  }
}
