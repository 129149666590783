@import '../../styles/vars.scss';

.table-wrapper.mobile {
    width: 100%;

    .team-member-mobile-cards-wrapper {
        width: 100%;
        max-height: 26vh;
        // max-height: 26%;
        height: fit-content;
        overflow-y: auto;
        background-color: white;
    
        .team-member-single-card-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            min-width: 100%;
            padding: 10px 5px;
            @include sectionShadow;
            margin: 17px 0;
            border-radius: 5px;

            .text-wrapper {
                text-align: left;

                .element {
                    font-size: 18px;

                    &.blue {
                        text-transform: uppercase;
                        color: $medium-blue;
                        font-size: 16px;
                        font-family: $fonts-bold;
                        border-radius: 5px;
                    }
                }
            }

            img {
                cursor: pointer;
            }
        }
    }
}