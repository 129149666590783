@import '../../styles/vars.scss';

.hero-section-wrapper {
    @include flex;
    margin-bottom: 100px;

    img {
        width: 50vw;
    }

    .text-section {
        padding-left: 100px;

        h1 {
            @include mediumHeading;
            
            &.small {
                color: $medium-blue;
                font-size: 30px;
            }
            &.medium {
                font-size: 48px;
                margin-top: 15px;
            }
            &.large {
                font-size: 60px;
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .hero-section-wrapper {
        flex-direction: column;
        justify-content: center;
        // width: 94vw;
        margin: 0;
        margin-bottom: 35px;
        
        .text-section {
            order: 2;
            padding: 0;
            margin-left: 20px;
            align-self: flex-start;

            h1 {

                &.small {
                    font-size: 18px;
                }
                &.medium {
                    font-size: 20px;
                }
                &.large {
                    font-size: 28px;
                }
            }
        }

        .image-section {
            // margin-left: 60px;
            overflow-x: hidden;
            width: 100vw;

            img {
                order: 1;
                width: 100vw;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
    .hero-section-wrapper {

        .text-section {

            h1 {
                &.small {
                    font-size: 20px;
                }
                &.medium {
                    font-size: 22px;
                }
                &.large {
                    font-size: 34px;
                }
            }
        }
    }
}