@import "../../styles/vars.scss";

.popup-wrapper {
  display: none;
  // display: block;
  width: 400px;
  height: fit-content;
  position: absolute;
//   right: -10px;
//   bottom: 0;
  z-index: 5;

  &.mobile {

  }


  .content-wrapper {
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: $white;
    border-radius: 8px;
    padding: 20px;
    @include sectionShadow;
    
    h4 {
      font-size: 14px;
      font-family: $fonts-medium;
      opacity: 0.8;
      text-transform: none;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .data-wrapper {
      @include flex;
      align-items: flex-start;
      flex-direction: column;
      position: relative;

      .popup-row {
        @include flex;
        font-size: 16px;
        font-family: $fonts-semi-bold;
        text-transform: none;
        padding: 10px 0;

        div {
          padding-left: 5px;
        }
      }
    }
  }

  .img-wrapper {
    z-index: -100;
    position: relative;
  }

  .info-icon.arrow {
    position: absolute;
    left: -33px;
    bottom: 60px;
    z-index: -100;
  }
}

@media screen and (max-width: 1300px) {
  .popup-wrapper.mobile {
    display: block;
    position: relative;
    min-width: 100%;
    min-height: 100%;

    .content-wrapper {
      position: relative;
      min-height: 100%;

      h4 {
        font-size: 22px;
        font-family: $fonts-bold;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .data-wrapper {
        .popup-row {
          font-size: 20px;
          font-family: $fonts-semi-bold;
          padding-bottom: 15px;
        }
      }
    }

    .img-wrapper {
      display: none;
    }
  }
}