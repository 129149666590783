@font-face {
    font-family: 'light';
    src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'regular';
    src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'medium';
    src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'semi-bold';
    src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'bold';
    src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'extra-bold';
    src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'black';
    src: url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
}

