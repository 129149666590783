@import '../../styles/vars.scss';

.header-wrapper {
    @include flex;
    background-color: $white;
    border-radius: 8px;
    border-bottom: 5px solid $medium-blue;
    padding:  0px 15px;
    margin: 15px auto;
    min-height: 80px;
    align-self: center;
    @include sectionShadow;
    position: fixed;
    min-width: 92vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 12;

    &.registration {
        position: relative;
        // display: inline;
        left: 0;
        transform: translate(0, 0);
        justify-content: center;
        height: 80px;
        align-self: flex-start;
        width: 94%;

        div {
            font-size: 22px;
            font-family: $fonts-bold;
            margin: 0;
        }

        .logo {
            left: 15px;
            position: absolute;
            width: 68px;
        }
    }

    .mobile {
        display: none;
    }

    .header-section {
        @include flex;

        .nav {
            @include flex;
            padding-left: 0;

            li {
                @include smallHeading;
                font-size: 16px;
                text-transform: uppercase;
                list-style-type: none;
                white-space: nowrap;
                padding: 0 20px;
                cursor: pointer;
                transition: 0.5s;

                &:hover {
                    color: $dark-blue;
                    transition: 0.5s;
                }
            }
        }

        img {
            width: 70px;
            height: auto;
        }

        button {
            @include primaryBttn;
        }
    }
}

// @media screen and (max-width: 1600px) {
//     .header-wrapper {
//         width: 55%;

//         .header-section .nav li {
//             font-size: 14px;
//         }
//     }
// }

@media screen and (max-width: 1099px) {
    .header-wrapper {
        all: initial;
        width: 64%;
        min-height: 80px;
        position: absolute;
        align-self: flex-start;
        // @include flex;
        // justify-content: flex-start;

        .header-section,
        .button-wrapper {
            display: none;
        }

        .mobile {
            min-height: 100%;
            width: 100%;
            cursor: pointer;
            z-index: 10;
            display: block;
            @include flex;

            .background-wrapper {
                min-width: 100vw;
                height: 100vh;
                flex-grow: 1;
                background-color: rgba(255, 255, 255, 0.58);
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 1;
            }

            .nav {
                // background-color: $white;
                // @include sectionShadow;
                margin: 30px;
                // border-bottom: 1px solid $medium-blue;
                border-radius: 8px;
                width: 17%;
                height: 42px;
                width: 40px;
                @include flex;
                justify-content: center;

                &.opened {
                    all: initial;
                    background-color: $white;
                    @include flex;
                    flex-direction: column;
                    // min-width: 95vw;
                    min-width: 100vw;
                    padding: 10px 0;
                    height: 522px;
                    @include bottomShadow;
                    border-bottom: 12px solid $medium-blue;
                    border-radius: 20px;
                    z-index: 5;

                    img {
                        align-self: flex-start;
                        cursor: pointer;
                        height: 40px;
                        background-image: url('../../assets/images/blue-delete-icon.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        @include sectionShadow;
                        border-radius: 8px;
                        margin-left: 20px;
                        border-bottom: 2px solid $medium-blue;
                    }

                    .opened-nav {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 90%;
                        height: 100%;
                        // min-width: 100vw;
                        // background-color: $white;
                        // color: $black-text;
                        // position: absolute;

                        li {
                            list-style: none;
                            @include smallHeading;
                            font-size: 16px;
                            padding-bottom: 30px;
                            cursor: pointer;
                            text-transform: uppercase;
                        }

                        button {
                            @include primaryBttn;
                            width: 100%;
                            @include smallHeadingMobile;
                            font-size: 14px;
                            color: $white;
                            border-radius: 6px;
                        }
                    }
                }

                img {
                    width: 40px;
                    padding: 20px;
                    background-image: url('../../assets/images/responsive-menu.png');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1099px) {
    .header-wrapper {
        all: initial;
        width: 60%;
        min-height: 80px;
        position: absolute;
        left: 0;
        // @include flex;
        // justify-content: flex-start;

        &.registration {
            display: flex;
            align-items: center;
            @include sectionShadow;
            border-bottom: 5px solid $medium-blue;
            min-width: 92vw;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 8px;

            .center {
                font-size: 20px;
            }
        }

        .header-section,
        .button-wrapper {
            display: none;
        }

        .mobile {
            min-height: 100%;
            width: 100%;
            cursor: pointer;
            z-index: 10;
            display: block;
            @include flex;

            .nav {
                background-color: $white;
                // @include sectionShadow;
                // margin: 30px;
                // border-bottom: 4px solid $medium-blue;
                // border-radius: 8px;
                width: 90px;
                @include flex;
                justify-content: flex-start;

                img {
                    min-width: 40px;
                    max-width: 40px;
                    min-height: 40px;
                    z-index: 11;
                    @include sectionShadow;
                    // margin: 30px;
                    border-bottom: 4px solid $medium-blue;
                    border-radius: 8px;
                    // padding: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .header-wrapper {
        all: initial;
        width: 60%;
        min-height: 80px;
        position: absolute;
        left: 0;
        // @include flex;
        // justify-content: flex-start;

        &.registration {
            display: flex;
            align-items: center;
            @include sectionShadow;
            border-bottom: 5px solid $medium-blue;
            min-width: 92vw;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 8px;

            .center {
                font-size: 16px;
            }
        }

        .header-section,
        .button-wrapper {
            display: none;
        }

        .mobile {
            min-height: 100%;
            width: 100%;
            cursor: pointer;
            z-index: 10;
            display: block;
            @include flex;

            .nav {
                // background-color: $white;
                // @include sectionShadow;
                margin: 30px;
                // border-bottom: 4px solid $medium-blue;
                // border-radius: 8px;
                width: 90px;
                @include flex;
                justify-content: flex-start;

                img {
                    min-width: 40px;
                    max-width: 40px;
                    min-height: 40px;
                    z-index: 11;
                    @include sectionShadow;
                    border-bottom: 4px solid $medium-blue;
                    border-radius: 8px;
                    // padding: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .registration-initial-page-wrapper.mobile {
        padding-bottom: 0;
    }

    .header-wrapper {
        width: 100%;

        &.registration {
            display: flex;
            align-items: center;
            @include sectionShadow;
            border-bottom: 5px solid $medium-blue;
            min-width: 85vw;
            width: 70%;
            border-radius: 8px;
            align-self: flex-start;
            justify-self: center;
            margin-top: 10px;

            .center {
                font-size: 20px;
            }
        }

        .mobile {

            .logo {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 50px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .header-wrapper {
        &.registration {
            .center {
                font-size: 18px;
            }

            .logo {
                width: 53px;
                height: 53px;
            }
        }
    }
}