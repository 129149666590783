@import '../../styles/vars.scss';

.transportation-section-wrapper {
    @include flex;
    flex-direction: column;
    margin: 100px 70px;

    h1 {
        @include mediumHeading;
        margin-bottom: 47px;
    }

    .mobile-wrapper {
        display: none;
    }

    // .options-wrapper {
    //     @include flex;
    //     font-size: 20px;
    //     font-family: $fonts-bold;
    //     text-transform: uppercase;
    //     border: 1px solid $medium-blue;
    //     border-radius: 8px;
    //     margin-bottom: 40px;
    //     width: 600px;
    //     height: 45px;

    //     .destination {
    //         font-size: 18px;
    //         font-family: $fonts-regular;
    //         color: $black-text;
    //         padding: 12px 20px;
    //         border-radius: 8px;
    //         width: 300px;
    //         text-align: center;
    //         cursor: pointer;
    //         transition: 0.5s;

    //         &.selected {
    //             background-color: $medium-blue;
    //             font-family: $fonts-bold;
    //             color: $white;
    //             transition: 0.5s;
    //         }
    //     }
    // }

    .options-wrapper {
        @include flex;
        font-size: 20px;
        font-family: $fonts-regular;
        text-transform: uppercase;
        border: 1px solid $medium-blue;
        border-radius: 8px;
        height: 45px;
        text-align: center;
        margin-bottom: 40px;
    
        .destination {
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 5px 0;
          width: 300px;
          height: 45px;
          border-left: 1px solid $medium-blue;
          // border-radius: 8px;
          cursor: pointer;
          transition: 0.5s;
          // height: 100%;
    
          &.selected {
            background-color: $medium-blue;
            border-left: none;
            color: $white;
            margin: 0;
            font-family: $fonts-bold;
            border-right: none;
            border-radius: 8px;
          }
          &.selected + .destination {
            border-left: none;
          }
          &.first {
            border-left: none;
          }
          &.last {
            border-right: none;
          }
          &:hover {
            opacity: 0.8;
            transition: 0.5s;
          }
        }
      }

    .main-content {
        @include flex;
        justify-content: center;

        // .map, .info-wrapper {
        //     width: 40%;
        // }

        .map {
            width: 44vw;
            height: 502px;
            background-color: $light-blue;
            border-radius: 30px;

            &.pointer {
                cursor: pointer;
            }

            iframe {
                border-radius: 30px;
            }
        }

        .info-wrapper {
            @include flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 20px;
            width: 44vw;
            transition: all 2s ease;

            .option-wrapper {
                display: flex;
                border-bottom: 2px solid $medium-blue;
                padding-bottom: 10px;
                width: 100%;
                transition: all 0.5s ease;

                &.selected {
                    flex-direction: column;
                    transition: 2s;
                }
                &.last {
                    border-bottom: none;
                }

                h2 {
                    font-size: 22px;
                    font-family: $fonts-bold;
                    text-transform: uppercase;
                    padding-right: 15px;
                }

                p {
                    // transform: translate(20px, 10px);
                    transition: all 0.5s ease;
                    line-height: 25px;
                    font-family: $fonts-medium;
                }

                img {
                    cursor: pointer;
                    transition: all 0.5s ease;
                }

            }
        }
    }
}

@media screen and (max-width: 1099px) {
    .transportation-section-wrapper {
        margin: 0 20px;

        h1 {
            @include mediumHeadingMobile;
            margin-bottom: 20px;
        }

        .options-wrapper,
        .main-content,
        .info-wrapper {
            display: none;
        }

        .mobile-wrapper {
            display: block;

            .option {
                @include smallHeadingMobile;
                text-transform: uppercase;
                text-align: center;
                border: 1px solid $medium-blue;
                padding: 10px 0;
                border-radius: 8px;
                margin-bottom: 30px;
            }

            .transport-wrapper {
                margin-top: 10px;

                .transport-heading {
                    @include smallHeadingMobile;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    margin-top: 20px;
                }

                .text {
                    @include smallHeading;
                    font-family: $fonts-medium;
                    line-height: 22px;
                    padding-bottom: 30px;

                    &.border {
                        border-bottom: 2px solid $medium-blue;
                    }
                }

                .map {
                    width: 100%;
                    height: 200px;
                    background-color: $light-blue;
                    border-radius: 15px;
                    margin-bottom: 50px;
                }
            }
        }
    }
}