@import '../../styles/vars.scss';

.about-section-wrapper {
    @include flex;
    padding: 10px 100px;
    height: 100%;
    margin-bottom: 100px;

    img {
        min-width: 45vw;
    }

    .text-section {
        flex-direction: column;
        padding: 0 100px;
        h1 {
            @include mediumHeading;
            margin-bottom: 50px;
        }

        p {
            @include smallHeading;
            line-height: 25px;
            font-family: $fonts-medium;
            margin-bottom: 25px;
            width: 100%;
        }
    }

    button {
        @include primaryBttn;
        width: 100%;
        margin: 8px 0;
    }
}

@media screen and (max-width: 768px) {
    .about-section-wrapper {
        padding: 0;
        flex-direction: column;
        margin-bottom: 62px;

        img {
            object-fit: contain;
            width: 100vw;
        }

        .text-section {
            padding: 0 20px;

            h1 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1250px) {
    .about-section-wrapper {
        padding: 0 10px;
        overflow-x: hidden;
        
        .text-section {
            padding: 0 10px;

            h1 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}