@import '../../../styles/vars.scss';

.registration.second-tab {
    height: 90%;

    .section {
        height: 70%;
    }

    .flex.mobile {
        display: none;
    }

    .tab.center.second {
        display: none;
    }

    .section-main-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // overflow-y: auto;
        // @include scrollbar;

        &.empty {
            // justify-content: space-between;

            .add {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                align-self: center;
            }
        }

        .table-wrapper {
            @include flex;
            flex-direction: column;
            width: 99%;
            margin-top: 20px;

            &.mobile {
                display: none;
            }

            .table-header-wrapper {
                @include flex;
                justify-content: flex-start;
                width: 100%;

                .header-element {
                    padding: 5px;
                    @include smallestHeading;
                    font-size: 14px;

                    &.small {
                        flex: 1;
                        width: 75px;
                    }
                    &.medium {
                        flex: 2;
                        width: 160px;
                    }
                    &.large {
                        flex: 3;
                        width: 290px;
                    }
                }
            }

            .table-elements-wrapper {
                max-height: calc(68vh - 200px);
                // position: relative;
                overflow-y: auto;
                overflow-x: visible;
                @include scrollbar;
                justify-content: flex-start;
            }
        }

        .add {
            @include secondaryBttn;
            border-style: dashed;
            margin-top: 15px;
            // margin-left: 0;
            // margin-right: 0;
            width: 99%;
            transition: 0.5s;
            font-size: 14px;
            font-family: $fonts-bold;

            &:hover {
                background-color: $light-background;
                transition: 0.5s;
            }
            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }

        .empty-state {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .continue-button-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 1300px) {
    .registration {

        .registration-page-wrapper.full-height {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            flex-grow: 1;
            align-items: stretch;
            margin-top: 0;
            margin-bottom: 0;
        }

        &.second-tab {
            height: auto;
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            flex-grow: 1;
            align-items: stretch;
            height: 63%;
            height: 100%;
            // overflow-y: auto;
            // @include scrollbar;

            .tab.center.second {
                display: block;
            }

            .section {
                height: 100%;
                overflow-y: auto;
                padding-bottom: 0;

                .section-top-wrapper {
                    margin-top: 0;
                }

              .section-main-wrapper {
                position: relative;
                // overflow-y: scroll;
                // min-height: none;
                // min-height: 80%;
                justify-content: none;
                min-height: 70%;

                .table-wrapper {
                    &.desktop {
                        display: none;
                    }
                    &.mobile {
                        display: flex;
                        flex-direction: column;
                        height: fit-content;
                        align-items: stretch;
                        justify-content: space-between;
                    }
                }
                .continue-button-mobile {
                  display: block;
                //   position: absolute;
                //   bottom: 0;
                  @include secondaryBttn;
                  margin-left: 0;
                  margin-right: 0;
                  width: 100%;
                  font-size: 16px;
      
                  &:disabled {
                    opacity: 0.5;
                    cursor: default;
                  }
                }

              }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .registration {
        &.second-tab {
            .section {
                .section-main-wrapper {
                    .continue-button-mobile {
                        display: block;
                        position: relative;
                    }
                }
            }
        }
    }
}

