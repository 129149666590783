@import '../../styles/vars.scss';

.gfl-section-wrapper {
    @include flex;
    flex-direction: column;
    // padding: 0 40px;
    margin: 100px 70px;

    h1 {
        @include mediumHeading;
    }

    .data-gfl-wrapper {
        @include flex;

        p {
            max-width: 45vw;
            padding: 0 20px;
            font-size: 18px;
            font-family: $fonts-medium;
            line-height: 25px;
            align-self: flex-start;
        }

        .paragraph-wrapper {
            display: flex;
            // justify-content: flex-start;
            align-items: flex-start;

            p {
                margin-top: 0;
            }
        }

        .gfl-wrapper {
            @include flex;
            @include scrollbar;
            overflow-x: scroll;
            width: 50vw;

            .gfl {
                @include flex;
                flex-direction: column;
                position: relative;
                width: fit-content;
                // @include bottomShadow;

                img {
                    object-fit: cover;
                    width: 37vw;
                }

                h2 {
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    font-size: 22px;
                    font-family: $fonts-bold;
                    color: $white;
                    background-color: $dark-blue;
                    width: 96%; 
                    padding: 10px;
                    margin-bottom: 5px;
                    border-radius: 0 0 30px 30px;
                }
            }

        }
    }
}

@media screen and (max-width: 1099px) {
    .gfl-section-wrapper {
        // margin: 20px auto;
        margin-left: 0;
        margin-top: 0;
        margin-right: 20px;
        // margin: 0 20px;
        width: 100%;

        h1 {
            @include mediumHeadingMobile;
        }

        .data-gfl-wrapper {
            flex-direction: column;
            width: 95%;

            p {
                @include smallHeadingMobile;
                font-family: $fonts-medium;
                padding: 0;
                min-width: 100%;
            }

            .text-gfl-wrapper {
                width: 100%;
            }

            .gfl-wrapper {
                flex-direction: column;
                overflow: visible;
                width: 105%;
                border-radius: 15px;

                .gfl {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    h2 {
                        @include smallHeadingMobile;
                        color: $white;
                        width: 96%;
                        text-transform: uppercase;
                        border-radius: 0 0 15px 15px;
                    }
                }
            }
        }
    }
}