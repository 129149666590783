@import "../../styles/vars.scss";

.registration-initial-page-wrapper {
  @include flex;
  flex-direction: column;
  // justify-content: flex-center;
  width: 100vw;
  height: calc(100vh - 40px);
  // min-height: 95vh;
  // position: relative;
  background-image: url("../../assets/images/mountains-registration.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: 100%;
  object-fit: cover;
  padding-bottom: 20px;

  &.start {
    justify-content: flex-start;
    background-color: $white;
    background-image: none;
    // min-height: 100vh;
  }

  &.mobile {
    background-image: none;
  }

  .input-wrapper {
    margin: 0 32px 15px 0;
    width: 100%;
    position: relative;
    flex: 1;

    &.small {
      flex: 1;
      max-width: 200px;
    }
    &.medium {
      flex: 1.2;
      max-width: 250px;
    }
    &.large {
      flex: 2;
      max-width: 450px;
    }

    p {
      @include smallestHeading;
      font-size: 14px;

      .red {
        color: $red;
      }
      .bold {
        font-family: $fonts-bold;
      }
    }

    .label-wrapper {
      @include flex;
    }

    .calendar-icon {
      position: absolute;
      right: 8px;
      top: 13px;
    }

    .calendar-wrapper {
      position: relative;
      max-width: 185px;

      .react-datepicker__year-dropdown {
        height: 220px;
        overflow-y: scroll;
        @include scrollbar;
      }
    }

    .extra-info {
      position: absolute;
      margin-top: 8px;
      white-space: nowrap;
    }

    .text-input-wrapper {
      position: relative;
      max-width: 245px;

      .input-text {
        position: absolute;
        right: 8px;
        top: 12.5px;
        padding-left: 8px;
        border-left: 1px solid $medium-blue;
        font-size: 14px;
        font-family: $fonts-regular;
      }
    }

    .input-arrow-wrapper {
      @include flex;
      position: relative;
      // flex: 2;
      max-width: 450px;
    }

    .arrow {
      position: absolute;
      right: 15px;
      cursor: pointer;
      transition: 0.5s;

      &.selected {
        transform: rotate(180deg);
        transition: 0.5s;
      }
    }

    input {
      margin: 0;
      height: 43px;
      width: 100%;
      border-radius: 8px;
      border-style: none;
      @include smallestHeading;
      font-family: $fonts-medium;
      padding: 0 10px;
      border: 1px solid #043c5e6a;
      outline: none;
    }

    .counter-box-wrapper {
      @include flex;
      max-width: 250px;
      height: 43px;
      border: 1px solid #043c5e6a;
      border-radius: 8px;
      padding: 0 10px;
      flex: 1;

      .icon {
        cursor: pointer;
        padding: 0 5px;

        &.active {
          fill: $black-text;
        }
      }
    }

    .dropdown-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: scroll;
      max-height: 300px;
      @include scrollbar;
      position: absolute;
      background-color: $white;
      @include sectionShadow;
      border-radius: 8px;
      padding: 5px;
      z-index: 100;
      top: 50px;
      width: 100%;

      &.small {
        max-height: 120px;
      }

      .dropdown-element {
        padding: 10px 5px;
        transition: 0.5s;
        cursor: pointer;
        font-size: 18px;
        border-radius: 8px;

        &:hover {
          background-color: $light-blue;
          transition: 0.5s;
        }
      }
    }
  }

  .back-bttn-wrapper {
    display: flex;
    margin-top: 80px;
    margin-left: 33px;
    align-self: flex-start;
    cursor: pointer;

    h2 {
      @include smallHeadingMobile;
      font-size: 14px;
      margin-left: 8px;
      align-self: center;
    }
  }

  .data-registration-wrapper {
    @include flex;
    justify-content: center;
    // height: calc(100% - 350px);
    // height: 800px;
    height: 100%;
    width: fit-content;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    background-color: $white;
    border-radius: 20px;
    margin-top: 30px;
    @include sectionShadow;
    margin-bottom: 0px;

    &.mobile {
      width: 100%;
      box-shadow: none;
      background-image: none;
      margin-bottom: 10px;
      @include sectionShadow;
      width: 92%;

      .card.left {
        height: 100%;
        min-width: 90%;
        position: relative;
        width: 100%;
        overflow: hidden;

        &.mobile {
          img {
            // height: 550px;
            width: 100%;
            height: 100%;
          }
        }

        h1 {
          @include smallHeadingMobile;
          font-size: 18px;
          text-transform: uppercase;
          color: $white;
          text-align: center;
        }

        img {
          object-fit: cover;
          display: block;
          z-index: 0;
        }
      }
    }
    &.registered {
      margin-top: 0;
      height: 80vh;
    }

    .heading {
      @include largerHeading;

      &.larger {
        font-family: $fonts-extra-bold;
      }
      &.smaller {
        font-size: 20px;
        margin-top: 15px;
      }
    }

    .card {
      @include flex;
      flex-direction: column;
      justify-content: center;
      padding: 27px 15px;
      border-radius: 20px;

      img {
        z-index: 1;
      }

      &.left {
        background-color: $dark-blue;
        @include sectionShadow;
        max-width: 50%;
        // height: 92.5%;
        // height: calc(100% - 50px);
        height: 100%;
        flex: 1;

        h1 {
          color: $white;
          margin-bottom: 0;
          margin-top: 10px;
          @include largerHeading;
          color: $white;
        }

        img {
          height: 610px;
          // margin-bottom: 20px;
        }

        &.registered {
          padding: 27px 0 0 0;
          // min-width: 50%;
          min-width: 569px;
          height: 100%;
          @include flex;

          h1 {
            margin-top: 17px;
            margin-bottom: 60px;
          }

          // img {
          //   object-fit: contain;
          //   width: 100%;
          // }

          .img-wrapper {
            display: block;
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
            @include sectionShadow;

            img {
              display: block;
              min-width: 100%;
              min-height: 100%;
              object-fit: cover;
              border-radius: 0 0 8px 8px;
            }
          }
        }
        // &.mobile {
        //   display: none;
        // }
      }

      &.right {
        // padding: 27px 40px;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 50%;
        flex: 1;

        &.mobile  {
          height: 100%;
          min-width: 98%;
          flex-direction: row;
          padding-left: 0;
          padding-right: 0;
          align-items: flex-start;

          h1, .list-wrapper {
            margin: 0;
            align-self: flex-start;
            text-align: left;
          }

          button {
            margin: 0;
            min-width: 98%;
          }

          p {
            // min-width: 98%;
            padding: 0 5px;
          }
        }

        h1 {
          padding: 0 15px;
          white-space: wrap;
          text-transform: uppercase;
          text-align: center;
          margin: 0 25px;
        }

        .heading.smaller {
          margin-top: 35px;
        }

        .list-wrapper {
          margin: 0 25px;
          @include flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 50px;
          padding-bottom: 30px;
          text-transform: uppercase;
          position: relative;

          .row {
            @include flex;
            @include smallHeading;
            justify-content: flex-start;
            padding: 10px;
            position: relative;
            transition: 1s;

            .row-item {
              padding: 10px;

              &.last {
                position: relative;
              }
            }

            .info-icon {
              cursor: pointer;
              padding-left: 5px;

              // &.arrow {
              //   display: none;
              //   position: absolute;
              // }

              &:hover + .popup-wrapper {
                display: block;
                transition: 1s;
                bottom: -100%;
                left: 30%;
              }
            }
          }
        }

        button {
          @include primaryBttn;
          font-size: 17px;
          margin-right: 25px;
          margin-left: 25px;
          width: 90%;
        }

        p {
          margin-top: 10px;
          font-size: 14px;
          font-family: $fonts-regular;

          span {
            text-decoration: underline;
          }
        }

        &.registered {
          justify-content: space-between;
          height: 100%;
          flex: 1;
          min-width: 50%;
          position: relative;
          padding: 27px 0;
          min-width: 569px;
          // padding: 27px 15px;
          // padding: 20px;

          h1 {
            white-space: nowrap;
            margin: 10px 0;
            text-align: left;
          }

          .list-wrapper {
            align-items: flex-start;
            margin-left: 5px;
            padding-top: 60px;

            .row {
              @include smallHeading;
              font-size: 16px;
              text-transform: uppercase;
              white-space: nowrap;
  
              span {
                font-family: $fonts-bold;
              }
            }
          }

          p {
            bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .registration-initial-page-wrapper {

    &.start {
      justify-content: space-between;
      padding-bottom: 0;
      min-height: fit-content;
      position: relative;
    }
    &.team {
      min-height: fit-content;
      overflow-y: visible;
      min-height: 100vh;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 1100px) {
  .registration-initial-page-wrapper {

    .data-registration-wrapper.mobile {
      height: 100%;
      margin: 18% 0;

      .card.left {
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 699px) {
  .registration-initial-page-wrapper {

    .data-registration-wrapper.mobile {
      height: 100%;
      margin-bottom: 80px;

      .card.left {
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .registration-initial-page-wrapper {

    .data-registration-wrapper {

      .card.right.registered {
        min-width: 80vw;

        h1 {
          white-space: unset;
      }
    }

      .card.right {

        .list-wrapper {
          .row {
            .info-icon {
              cursor: pointer;
              padding-left: 5px;
    
              // &.arrow {
              //   display: none;
              //   position: absolute;
              // }
    
              &:hover + .popup-wrapper {
                display: none;
              }
            }
          }
        }
      }

      .card.left {
        display: none;
      }
    }
  }
}
