@import '../../styles/vars.scss';

.footer-wrapper {
    @include flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 13vh;
    background-color: $white;

    &.registration {
        height: 40px;
        background-color: $black-text;
        position: absolute;
        bottom: 0;
        padding: 3px 0;

        .contacts {
            @include flex;
            width: 100%;
            justify-content: center;
            
            .contact {
                color: $white;
                padding: 0 15px;

                &.line {
                    border-right:  1px solid $white;
                }

                span {
                    font-family: $fonts-bold;
                    font-size: 14px;
                }
            }
        }
        justify-content: center;
    }

    .section {
        @include flex;
        justify-content: center;
        color: $white;
        width: 100%;

        &.top {
            flex: 2;
            background-color: $dark-blue;
            border-radius: 30px 30px 0 0;

            div {
                padding: 0 20px;
                cursor: pointer;
            }
        }

        &.bottom {
            background-color: $black-text;
            flex: 1;

            img {
                padding: 5px 10px;
                cursor: pointer;
            }
        }

        &.nav {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-wrapper {
        height: 250px;

        &.downloads {

            .section {

                &.top {
                    border-radius: 0;
                }

                &.nav {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 15px 15px 0 0;

                    li {
                        @include smallHeading;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $white;
                        list-style-type: none;
                        margin: 15px 0;
                    }

                    button {
                        @include primaryBttn;
                        width: 90%;
                    }
                }

                &.bottom {
                    padding: 10px 0;
                }
            }
        }

        .section {
            flex-direction: column;

            &.top {
                @include smallestHeading;
                color: $white;
                padding: 18px 0;
                justify-content: center;
                align-content: center;

                div {
                    padding: 10px 0;
                }
            }

            &.bottom {
                flex-direction: row;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1099px) {
    .footer-wrapper {
        min-height: 100px;
        width: 100%;

        &.registration {
            flex-direction: column;
            flex-wrap: wrap;
            position: relative;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-wrapper {
        &.registration {
            height: fit-content;
            align-items: center;
            position: relative;
            // position: absolute;
            // bottom: 0;

            .contacts {
                flex-direction: column;
                flex-wrap: wrap;
                padding: 5px 0;

                .contact {
                    padding-bottom: 10px;
                    width: 95%;
                    text-align: center;
                    margin-top: 10px;

                    &.line {
                        border: none;
                        border-bottom: 1px solid $white;
                    }
                }
            }
        }
    }
}