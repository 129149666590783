@import '../../../styles/vars.scss';

.general-information-team-members-wrapper-mobile {

    .tab {
        width: 100%;
        padding: 10px;
        height: 40px;
        font-size: 16px;
        font-family: $fonts-bold;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        background-color: #EBF5FF;
        transition: 0.5s;
        border-radius: 0 0 20px 20px;
    }

    .sections-separator {
        border-bottom: 3px solid $medium-blue;
        margin: 40px 0 45px 0;
    }

    .registration {

        &.first-tab {
            padding: 0;

            .section {

                .section-main-wrapper {
                    flex-wrap: wrap;
                    flex-direction: column;
                    margin-right: 0;
                    width: 100%;

                    .input-wrapper {

                        &.small, &.large {
                            margin-right: 0;
                            min-width: 100%;

                            input, .counter-box-wrapper {
                                height: 50px;
                                max-width: 100%;
                                
                                p {
                                    font-size:  18px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.second-tab {
            .section {

                .section-main-wrapper {

                    .continue-button-mobile {
                        display: block;
                        @include secondaryBttn;
                        margin-left: 0;
                        margin-right: 0;
                        width: 100%;
                        font-size: 16px;
            
                        &:disabled {
                            opacity: 0.5;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .section {
            .section-top-wrapper {
                button {
                    display: none;
                }

                .text-wrapper {

                    p {
                        font-size: 20px;
                    }
                }
            }

            .section-main-wrapper {
                .input-wrapper {
                    p {
                        font-size: 16px;
                    }

                    input {
                        font-size: 18px;
                    }
                }

                button {
                    font-size: 16px;
                }
            }
        }
    }
}

// @media screen and (max-width: 1300px) {
//    .registration-initial-page-wrapper.start {
//     // overflow: scroll;
//    }
// }