@import "../../../styles/vars.scss";

.modal-wrapper {
    &.loading {
        left: 0;
        top: 0;

        .content-wrapper {
            width: 602px;
            height: 294px;
        }
    }
}