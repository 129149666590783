@import '../../styles/vars.scss';

.contact-section-wrapper {
    @include flex;
    justify-content: center;
    margin-bottom: 100px;
    // padding: 0 40px;
    // flex-wrap: wrap;
    width: 100%;

    .img-wrapper {
        display: block;
        width: 50%;
        height: auto;
        position: relative;
        overflow: hidden;

        .large-illustration {
            // flex: 1;
            display: block;
            max-width: 100%;
            max-height: 100%;
            // max-width: 777px;
            // object-fit: contain;
        }
    }

    .data-wrapper {
        display: flex;
        // flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-items: flex-start;
        margin-left: 30px;

        h1 {
            @include mediumHeading;
            margin-bottom: 10px;
            margin-left: 0;
        }

        p {
            font-size: 18px;
            margin-top: 10px;
            font-family: $fonts-medium;
        }

        .contacts-info-wrapper {
            @include flex;
            flex-direction: column;
            width: 100%;

            .contacts {
                @include flex;
                padding: 5px 10px;
                // width: 800px;
                width: 100%;
                min-height: 80px;
                color: $white;
                border-radius: 20px;
                margin: 20px 0;
                @include bottomShadow;
                font-size: 18px;
                font-family: $fonts-medium;

                &.light {
                    background-color: $medium-blue;
                }
                &.dark {
                    background-color: $dark-blue;
                }

                span {
                    font-family: $fonts-bold;
                    flex-wrap: nowrap;
                }

                .contact-row {
                    @include flex;
                    justify-content: center;
                    padding: 20px 10px;
                    flex: 1;
                    flex-wrap: nowrap;
                    min-height: 100%;
                    white-space: nowrap;

                    &.line {
                        border-right: 1px solid $white;
                    }
                }
            }
        }
    }
}

// @media screen and (min-width: 1024px) and (max-width: 1300px) {
//     .contact-section-wrapper {
//         // flex-wrap: wrap;
//         margin-bottom: 20px;
                
//         .data-wrapper {
//             .contacts-info-wrapper {
//                 .contacts {
//                     font-size: 20px;
//                     width: 600px;
//                 }
//             }
//         }
//     }
// }

@media screen and (max-width: 1099px) {
    .contact-section-wrapper {
        // margin: 0 20px;
        min-width: 100%;
        
        .img-wrapper {
            display: none;
        }

        .data-wrapper {
            // margin-left: 0;
            // margin-right: 30px;
            margin: 0;
            width: 95%;

            h1 {
                @include mediumHeadingMobile;
            }

            p {
                @include smallestHeading;
            }

            .contacts-info-wrapper {

                .contacts {
                    flex-direction: column;
                    width: calc(100vw - 150px);
                    border-radius: 15px;
                    width: 100%;

                    .contact-row {
                        font-size: 16px;

                        &.line {
                            border-right: none;
                            border-bottom: 1px solid $white;
                        }
                    }
                }
            }
        }
    }
}