@import "../../styles/vars.scss";

.package-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 70px 100px 70px;
  margin-bottom: 20px;
  background-image: url("../../assets/images/Package-Background.png");
  background-position: start;
  background-repeat: no-repeat;

  h1 {
    @include mediumHeading;
    margin-bottom: 54px;
  }

  .main-content {
    @include flex;
    background-color: $light-blue;
    @include sectionShadow;
    border-radius: 24px;
    position: relative;

    .section {
      @include flex;
      flex-direction: column;
      border-radius: 24px;
      // height: 539px;
      padding: 30px 40px;
      // @include sectionShadow;

      &.left {
        background-color: $black-text;
        color: $white;
        z-index: 1;
        flex: 1;
        border-radius: 24px;
        @include sectionShadow;

        h1 {
          color: $white;
          font-size: 24px;
          font-family: $fonts-bold;

          span {
            font-size: 40px;
            font-family: $fonts-extra-bold;
          }
        }
      }
      &.right {
        background-color: $light-blue;
        color: $black-text;
        border-radius: 0 24px 24px 0;
        z-index: 0;
        min-height: 100%;
        justify-content: center;
        text-align: center;
        flex: 1;
        left: -15px;
        text-transform: uppercase;
        // background-image: url("../../assets/images/snow.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        overflow: hidden;
        // object-fit: contain;

        h1 {
          @include mediumHeading;
          font-size: 28px;
          margin-bottom: 0;
        }

        p {
          text-transform: none;
        }

        button {
          @include primaryBttn;
          width: 100%;
          margin-top: 50px;
        }

        img {
          // height: 150px;
          position: absolute;
          right: 0;
          bottom: 0;
          border-radius: 0 0 24px 0;
        }
      }

      .list-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .list-element {
          @include flex;
          font-size: 20px;
          font-family: $fonts-semi-bold;
          text-transform: uppercase;
          padding: 15px 0;

          div {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1099px) {
  .package-section-wrapper {
    padding: 0 20px;
    background-image: none;

    h1 {
        @include smallHeading;
        font-family: $fonts-extra-bold;
        margin-bottom: 20px;
    }

    .main-content {
        flex-direction: column;
        width: 100%;

        .section {
            width: 100%;
            border-radius: 15px;
            background-color: $light-blue;

            &.left {
                bottom: -50px;
                z-index: 10;

                h1 {
                    @include smallHeading;
                    font-family: $fonts-bold;
                    color: $white;
                    margin-bottom: 20px;
    
                    span {
                        @include mediumHeading;
                        font-size: 22px;
                        color: $white;
                    }
                }

                .list-wrapper {

                    .list-element {
                        font-size: 14px;
                    }
                }
            }

            &.right {
                border-radius: 0 0 15px 15px;
                background-image: none;

                h1 {
                    @include smallHeading;
                    font-family: $fonts-extra-bold;
                }

                p {
                    @include smallestHeading;
                    font-family: $fonts-medium;
                }

                button {
                    margin-top: 20px;
                    font-size: 14px;
                }

                img {
                  display: none;
                }
            }
        }
    }
    
  }
}
