@import "../../../styles/vars.scss";

.modal-wrapper {
    position: absolute;
    @include flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #041b29e2;
    z-index: 1000;

    // left: 50%;
    // top: 30%;
    // transform: translate(-50%, 0);

    &.add {
      button {
        @include primaryBttn;
        width: 100%;
      }
    }

  .content-wrapper {
    @include flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    background-color: $white;
    @include sectionShadow;
    border-radius: 8px;
    padding: 25px 40px;
    left: 50%;
    top: 35%;
    transform: translate(-50%, 0);

    .top-wrapper {
      @include flex;
      flex-direction: column;

      h1 {
        @include smallHeading;
        font-family: $fonts-bold;
        text-transform: uppercase;
      }

      p {
        margin-top: 0;
        @include smallestHeading;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .modal-wrapper {
    all: unset;
    position: absolute;
    width: 100vw;

    .content-wrapper {
      width: 100vw;
      min-height: 100%;
      box-shadow: none;
      // height: 100vh;
    }

    &.add {
      // display: none;
    }
    &.mobile {
      display: block;
    }
  }
}
