@import "../../styles/vars.scss";

.schedule-section-wrapper {
  @include flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  background-color: $black-text;
  // background-image: url('../../assets/images/Event-Timeline.png');
  background-image: url("../../assets/images/dark-background.svg");
  // background-image: url('../../assets/images/Event-Timeline-new.png');
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: bottom center;
  object-fit: contain;
  background-size: 100%;
  width: 100%;
  border-radius: 24px;
  margin-bottom: 100px;
  border-radius: 30px 30px 32px 32px;

  h1 {
    @include mediumHeading;
    color: $white;
    margin: 40px 0 50px 0;
    text-align: center;
  }

  .desktop-wrapper {
    height: 100%;
  }

  .mobile-wrapper {
    display: none;
  }

  .events-wrapper {
    @include flex;
    font-size: 18px;
    border: 1px solid $medium-blue;
    border-radius: 10px;
    height: 45px;
    text-align: center;
    // margin-bottom: 10px;

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 5px 0;
      width: 300px;
      height: 45px;
      border-left: 1px solid $medium-blue;
      // border-radius: 8px;
      cursor: pointer;
      transition: 0.5s;
      // height: 100%;

      &.selected {
        background-color: $medium-blue;
        border-left: none;
        color: $white;
        margin: 0;
        font-family: $fonts-bold;
        border-right: none;
        border-radius: 8px;
      }
      &.selected + .option {
        border-left: none;
      }
      &.first {
        border-left: none;
      }
      &.last {
        border-right: none;
      }
      &:hover {
        opacity: 0.8;
        transition: 0.5s;
      }
    }
  }

  .schedule-wrapper {
    @include flex;
    justify-content: center;
    padding-bottom: 30px;
    // max-height: 75vh;
    // margin-top: 45px;

    .column {
      @include flex;
      flex-direction: column;
      padding: 10px 15px;
      height: 93%;
      position: relative;
      margin-top: 40px;

      &.left {
        align-items: flex-end;
      }
      &.center {
        margin-top: 0;
        top: 0;

        .line {
          position: absolute;
          z-index: 0;
          top: 0;
          // height: 100%;
          height: 750px;
          overflow: hidden;
        }
        .rectangle {
          z-index: 1;
          margin-bottom: 40px;

          &.first {
            margin-top: 50px;
          }
        }
      }
      &.right {
        align-items: flex-start;
      }

      .row {
        font-size: 18px;
        margin-bottom: 63.6px;
        // padding-bottom: 25px;

        &.light {
          font-family: $fonts-light;
        }
        &.bold {
          font-family: $fonts-bold;
        }
        &.first {
          margin-top: 30px;
        }
      }
    }
  }

  .note-bottom {
    font-family: $fonts-light;
    text-align: center;
    margin-top: 45px;
  }
}

@media screen and (max-width: 1099px) {
  .schedule-section-wrapper {
    all: initial;
    background-color: $black-text;
    border-radius: 15px;
    // min-height: 2300px;
    display: block;
    min-height: 2470px;
    margin-bottom: 60px;
    // position: relative;
    // overflow: auto;

    .desktop-wrapper {
      display: none;
    }

    .mobile-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      height: 2400px;
      width: 100%;
      padding: 10px 0 30px 0;
      // background-color: $black-text;

      .main-heading {
        font-size: 18px;
        order: 1;
        margin-bottom: 50px;
      }

      .events-wrapper {
        min-height: 100%;
        flex-direction: column;
        justify-content: center;
        order: 2;
        border: none;

        .section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 40px;

          .section-title {
            @include smallestHeading;
            color: $white;
            font-family: $fonts-bold;
            text-transform: uppercase;
            border: 2px solid $medium-blue;
            border-radius: 6px;
            align-self: center;
            margin: 10px 0;
            padding: 10px 20px;
            width: 92%;
          }

          .data-icons-wrapper {
            display: flex;
            min-width: 100%;
            position: relative;
            padding-left: calc(4vw - 10px);

            .line-wrapper {
              position: absolute;

              .line {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 13px;
                // // height: 100%;
                height: 675px;
                // overflow: hidden;
              }
            }
          }

          .row {
            @include smallestHeading;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 30px;

            &.last {
              padding-bottom: 20px;
            }

            .rectangle {
                width: 28px;
                height: 28px;
                margin-right: 5px;
                margin-top: 10px;
                z-index: 10;
              }

            .text-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                color: $white;
                text-align: left;

                .bold {
                    font-family: $fonts-extra-bold;
                    margin-top: 30px;
                    text-transform: uppercase;
                  }
            }
          }
        }
      }
    }
  }
}
