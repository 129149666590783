@import '../../styles/vars.scss';

@media screen and (max-width: 1300px) {
    .registration-initial-page-wrapper {

        .data-registration-wrapper {
            .card.right.registered {
                margin-top: 20px;
                width: 100vw;
                min-width: 100vw;
                max-width: 100vw;
    
                .top {
    
                    .list-wrapper {
    
                        .row {
                            flex-direction: column;
                            align-items: flex-start;
                            font-size: 18px;
                        }
                    }
                }

                p {
                    font-size: 18px;
                    padding: 0 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .registration-initial-page-wrapper {
        height: 100vh;
        min-height: 100vh;
        .data-registration-wrapper {

            .card.right.mobile {
                max-height: 90%;

                &.registered {
                    min-height: 100vh;
                    min-width: 100vw;
                }

                .heading.larger {
                    font-size: 20px;
                }

                .heading.smaller {
                    font-size: 18px;
                    margin-top: 5px;
                }

                .list-wrapper {
                    padding: 0;

                    .row {
                        font-size: 16px;

                        .row-item {
                            padding: 5px 10px;
                        }

                        .info-icon {
                            min-width: 20px;
                            min-height: 20px;
                            padding-left: 15px;
                            padding-top: 7px;
                        }
                    }
                }
            }
        }
    }
}

