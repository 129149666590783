@import '../../styles/vars.scss';

.table-elements-wrapper {
    @include flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background-color: $white;
    // overflow-y: auto;
    // @include scrollbar;

    .single-member-wrapper {
        width: 100%;
    }

    .single-member {
        @include flex;
        justify-content: flex-start;
        padding: 5px 10px;
        @include sectionShadow;
        border-radius: 8px;
        width: 100%;
        min-height: 60px;
        z-index: 2;
        margin-top: 5px;

        &.last {
            margin-bottom: 5px;
        }

        .element {
            @include smallestHeading;
            font-family: $fonts-medium;
            padding: 10px 5px;
            border-right: 1px solid $black-text;
            border-right: 1px solid rgba(122, 122, 122, 0.578);
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;

            &.small {
                flex: 1;
                width: 75px;
            }
            &.medium {
                flex: 2;
                width: 160px;
            }
            &.large {
                flex: 3;
                width: 290px;
            }
            &.flex {
                @include flex;
            }
            &.start {
                justify-content: flex-start;
            }
            &.last {
                border-right: none;
            }

            .text {
                @include flex;
            }

            .edit {
                cursor: pointer;
            }

            img {
                padding-left: 10px;

                &:hover + .arrow-data-wrapper {
                    display: block;
                    pointer-events: none;
                    transition: 0.5s;
                    position: absolute;
                }
            }
        }

        .arrow-data-wrapper {
            position: relative;
            display: none;

            .arrow-wrapper {
                position: absolute;
                left: 110px;
                top: -20px;
            }

            .more-data-wrapper {
                width: 180px;
                position: relative;
                // min-width: 160px;
                width: fit-content;
                height: fit-content;
                @include sectionShadow;
                padding: 5px 10px;
                border-radius: 8px;
                background-color: $white;
                position: absolute;
                // right: 11%;
                left: 150px;
                right: 0;
                top: -60px;
                @include smallestHeading;
                font-family: $fonts-bold;
                z-index: 5;
                transition: 0.5s;
    
                p {
                    font-size: 14px;
                    font-family: $fonts-medium;
                    color: $black-text;
                }
    
                &.last {
                    right: 0;
                }
    
                .info-wrapper {
                    @include flex;
                    justify-content: flex-start;
                    padding: 5px 0;
    
                    img {
                        margin-right: 10px;
                    }
                }
            }

        }
    }

    .team-leader {
        background-color: $light-medium-blue;
        border-radius: 0 0 8px 8px;
        @include largerHeading;
        @include sectionShadow;
        font-size: 14px;
        width: 100%;
        @include flex;
        justify-content: center;
        padding: 5px 10px;
        z-index: 1;
        height: 30px;
        margin-bottom: 5px;
    }
}