@import "./fonts";

$fonts-light: "light";
$fonts-regular: "regular";
$fonts-medium: "medium";
$fonts-semi-bold: "semi-bold";
$fonts-bold: "bold";
$fonts-extra-bold: "extra-bold";
$fonts-black: "black";

$white: #ffffff;
$light-background: #F4F9FF;
$light-blue: #eaf7ff;
$light-medium-blue: #B0D1FA;
$medium-blue: #0083e5;
$dark-blue: #043c5e;
$black-text: #041b29;
$red: #E5004C;

@mixin flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin smallestHeading {
  font-size: 16px;
  font-family: $fonts-regular;
  color: $black-text;
}

@mixin smallHeading {
  font-size: 18px;
  font-family: $fonts-semi-bold;
  color: $black-text;
}

@mixin smallHeadingMobile {
  font-size: 16px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin mediumHeadingMobile {
  font-size: 18px;
  font-family: $fonts-extra-bold;
  color: $black-text;
}

@mixin largerHeading {
  font-size: 24px;
  font-family: $fonts-bold;
  color: $black-text;
}

@mixin mediumHeading {
  font-size: 34px;
  font-family: $fonts-extra-bold;
  color: $black-text;
  text-transform: uppercase;
}

@mixin primaryBttn {
  padding: 0.9em 1.4em;
  background-color: $medium-blue;
  border-radius: 8px;
  border-style: none;
  color: white;
  cursor: pointer;
  margin: 1em;
  transition: 0.5s;
  box-sizing: border-box;
  margin: 0.5em;
  font-size: 1rem;
  font-family: $fonts-bold;
  text-transform: uppercase;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
    transition: 0.5s;
  }
}

@mixin secondaryBttn {
  @include primaryBttn();
  padding: 0.9em 2.6em;
  color: $black-text;
  background-color: $white;
  font-size: 0.8rem;
  border: 1px solid $medium-blue;
}

@mixin basicShadow {
  // box-shadow: 0px 0px 16px -10px $shadow-color;
  box-shadow: 0px 0px 10px -4.5px rgba(0, 0, 0, 0.3); //equal on 4 sides
}

@mixin bottomShadowLight {
  box-shadow: 0px 3px 6px #00000017;
}

@mixin sectionShadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 52, 112, 0.15);
}

@mixin bottomShadow {
  box-shadow: 0 5px 5px rgba(0, 52, 112, 0.15);
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin hiddenScrollbar() {
  scrollbar-color: transparent;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
