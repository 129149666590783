@import "../../styles/vars.scss";

.downloads-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content-wrapper {
    margin: 120px 60px 50px 60px;
    position: relative;

    .flex {
      display: flex;
    }

    .top-wrapper {
      .heading-wrapper {
        display: flex;
        align-items: center;

        h4 {
          @include smallHeadingMobile;
          font-size: 14px;
          text-transform: uppercase;
        }

        img {
          height: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }

      h1 {
        text-align: center;
        justify-content: center;
        flex: 3;
        @include mediumHeading;
      }
    }

    .main-content {
      @include flex;
      background-color: $light-blue;
      @include sectionShadow;
      border-radius: 24px;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;

      .section {
        @include flex;
        flex-direction: column;
        border-radius: 24px;
        padding: 30px 40px;

        &.left {
          z-index: 1;
          flex: 1;
          padding: 0;
          height: 120%;
          //   border-radius: 24px;

          img {
            width: 50vw;
          }
        }

        &.right {
          background-color: $light-blue;
          color: $black-text;
          z-index: 0;
          min-height: 100%;
          text-align: left;
          flex: 1;
          justify-content: flex-start;

          h1 {
            @include mediumHeading;
            font-size: 28px;
            margin-bottom: 0;
            left: 0;
            text-transform: uppercase;
          }

          p {
            text-transform: none;
            font-size: 20px;
            font-family: $fonts-regular;
            color: $black-text;
          }

          button {
            @include primaryBttn;
            width: 100%;
            margin-top: 50px;
          }

          .icon-text-wrapper {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
            width: 100%;
            text-decoration: none;

            img {
                width: 20px;
                margin-right: 10px;
            }

            .text {
                @include smallHeadingMobile;
                color: $medium-blue;
                text-decoration: none;
            }
          }
        }

        .list-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .list-element {
            @include flex;
            font-size: 20px;
            font-family: $fonts-semi-bold;
            text-transform: uppercase;
            padding: 15px 0;

            div {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  .footer-wrapper {
    bottom: 0;
  }
}


@media screen and (max-width: 768px) {
    .downloads-page-wrapper {

        .content-wrapper {
            margin: 100px auto;
            margin-bottom: 0;
            height: 100%;
            overflow-y: auto;
            @include scrollbar;

            .top-wrapper {
                padding-left: 40px;
            
                h1 {
                    display: none;
                }

                img {
                    margin-right: 10px;
                }
            }

            .main-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                background-color: white;
                box-shadow: none;
                // height: 100%;
                padding-left: 30px;
                padding-right: 30px;

                .section.left {
                    flex: 2;
                    min-width: 100%;

                    img {
                        min-width: 100%;
                    }
                }

                .section.right {
                    flex: 1;
                    padding-left: 0;
                    padding-right: 0;
                    background-color: white;
                    align-items: flex-start;

                    h1 {
                        @include mediumHeadingMobile;
                    }

                    p {
                        @include smallHeadingMobile;
                        font-family: $fonts-regular;
                    }
                }
            }
        }
    }
}
