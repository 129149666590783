@import '../../styles/vars.scss';

.info-wrapper.popup {
    padding: 0 15px;
                        
    &.padding {
        padding-bottom: 13px;
    }
    &.margin {
        margin-bottom: 50px;
    }
    &.border {
        border-bottom: 1px solid $medium-blue;
    }

    .row {
        padding: 20px 5px;

        .label {
            font-size: 20px;
            font-family: $fonts-regular;
        }

        .bold {
            font-size: 22px;
            font-family: $fonts-bold;
        }
    }

    .extra-text {
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 14px;
    }
}