@import '../../../styles/vars.scss';

.registration.third-tab {

    .tab {
        display: none;
    }

    .text-wrapper {
        width: 90%;
    }

    .submit {
        @include primaryBttn;
        height: 40px;
        font-size: 14px;
        @include flex;
        justify-content: center;
        white-space: nowrap;
        margin-left: 20px;

        &.mobile {
            display: none
        }
    }

    .section-main-wrapper {
        @include flex;
        width: 100%;

        .section {
            flex: 1;
            border-radius: 8px;
            @include sectionShadow;
            // height: 55vh;
            padding: 10px;
            width: 100%;

            h2 {
                @include smallHeading;
                font-family: $fonts-bold;
            }

            .info-wrapper {
                        
                &.padding {
                    padding-bottom: 13px;
                }
                &.margin {
                    margin-bottom: 50px;
                }
                &.border {
                    border-bottom: 1px solid $medium-blue;
                }

                .extra-text {
                    padding-top: 10px;
                    padding-bottom: 20px;
                    font-size: 14px;
                }
            }

            .section-info {
                overflow-y: auto;
                @include scrollbar;

                &.bottom.mobile {
                    display: none;
                }
            }

            .row {
                @include flex;
                padding: 10px 0;

                .medium {
                    font-family: $fonts-medium;
                }
                .bold {
                    font-family: $fonts-bold;
                }

                .icon {
                    margin-right: 5px;
                }
            }

            &.left {
                height: 55vh;
                background-color: #EBF5FF;
                margin-right: 30px;
                overflow-y: auto;
                @include scrollbar;

                .section-info {
                    min-width: 100%;
                }
            }

            &.right {
                height: 55vh;
                @include flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                box-shadow: none;
                padding-top: 0;
                padding-bottom: 0;

                .section-info {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    @include sectionShadow;
                    border-radius: 8px;
                    padding: 10px;
                    min-width: 100%;

                    &.top {
                        margin-bottom: 15px;
                    }
                    &.bottom {
                        .border {
                            border-bottom: 1px solid black;
                        }
                    }
                    &.mobile {
                        display: none;
                    }
                }

                .row {
                    justify-content: flex-start;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {

    .registration.third-tab {
        position: relative;

        .submit {

            &.desktop {
                display: none;
            }
            &.mobile {
                display: flex;
                width: 100%;
                height: 55px;
                margin: 0;
                margin-bottom: 40px;
            }
        }

        .section {

            .section-top-wrapper {
                flex-direction: column;

                .tab {
                    display: block;
                }

                button {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
            }

            .section-main-wrapper {
                flex-direction: column;

                h2 {
                    font-size: 20px;
                    text-transform: uppercase;
                }

                .info-wrapper {

                    .row {
                        padding-bottom: 20px;
                        flex-direction: row;

                        .medium {
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                    }

                    .extra-text {
                        font-size: 16px;
                    }
                }

                .section.left {
                    margin-right: 0;
                    margin-bottom: 15px;

                    .section-info {

                        &.top {
                            .row {
                                flex-direction: column;
                                font-size: 18px;
                            }
                        }

                        &.bottom.desktop {
                            display: none;
                        }

                        &.mobile {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;

                            h2 {
                                margin-bottom: 0;
                            }
                            
                            .info-wrapper {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                                padding-top: 5px;
                                margin-right: 0;
                                width: 100%;

                                &.margin {
                                    margin-bottom: 10px;
                                    border-bottom: 1px solid $dark-blue;
                                }

                                .flex {
                                    // display: flex;
                                    padding: 5px;

                                    .row {
                                        display: flex;
                                        font-family: $fonts-bold;
                                        font-size: 18px;
                                        
                                        &.first {
                                            padding-bottom: 0;
                                        }
                                        &.last {
                                            padding-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .section.right {
                    width: 100%;
                    padding: 0;

                    .section-info {

                        &.bottom {
                            margin-bottom: 35px;
                        }
                    }
                }

                .row {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}